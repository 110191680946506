import React from "react";
import { Reset } from "./Reset";
import { Theme } from "./Theme";

export const GlobalStyles = () => (
  <>
    <Reset />
    <Theme />
  </>
);
