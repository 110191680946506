import React from "react";
import styled from "styled-components";
import { GlobalStyles } from "../components/css/GlobalStyles";
import { ProfileCard } from "../components/Profile";
import { GithubIcon } from "../components/icons/GithubIcon";
import { TwitterIcon } from "../components/icons/TwitterIcon";
import { StaticImage } from "gatsby-plugin-image";
import { LinkedinLogo } from "../components/icons/LinkedinLogo";

const Wrapper = styled.main`
  background-size: cover;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: grid;
  place-content: center;
  padding: 1rem;
`;

const BackgroundImage = styled.div`
  .gatsby-image-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;

    img {
      object-fit: cover;
    }
  }

  :after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;

    background: linear-gradient(
      hsla(8, 79%, 33%, 0.2),
      hsla(128, 38%, 33%, 0.2)
    );
  }
`;

const OffsetProfileCard = styled(ProfileCard)`
  transform: translateY(3rem);
`;

const Grid = styled.div`
  display: grid;
  gap: 2rem;
  justify-items: center;
  text-align: center;
  padding-top: 4rem;
`;

const ProfilePictureContainer = styled.div`
  height: 12rem;
  width: 12rem;
  overflow: hidden;
  position: absolute;
  left: 50%;
  border-radius: 50%;
  border: 0.5rem solid var(--color-gray-900);
  transform: translate(-50%, -12rem);
  @media (min-width: 900px) {
    transform: translate(-50%, -13rem);
  }
  background: var(--color-gray-600);

  :after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    box-shadow: var(--shadow-medium-inset);
    border-radius: 50%;
  }

  .gatsby-image-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;

const Greeting = styled.h1``;

const Subtitle = styled.p`
  font-size: 0.8rem;
  color: var(--color-gray-400);
`;

const Links = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1.5rem;

  @media (max-width: 500px) {
    gap: 0.75rem;
  }
`;

const Link = styled.a`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  color: var(--color-green-300);
  text-decoration: none;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  @media (max-width: 500px) {
    svg {
      width: 1rem;
      height: 1rem;
    }
  }

  :hover,
  :focus {
    text-decoration: underline double;
    outline-offset: 4px;
  }
`;

// markup
const IndexPage = () => {
  return (
    <>
      <GlobalStyles />
      <Wrapper>
        <BackgroundImage>
          <StaticImage
            src="../images/yosemite_trees.jpg"
            alt="Lovely scene of trees in Yosemite National Park"
            placeholder="blurred"
            width={2688}
            height={2016}
          />
        </BackgroundImage>
        <OffsetProfileCard>
          <Grid>
            <ProfilePictureContainer>
              <StaticImage
                src="../images/kevin.jpg"
                alt="Photograph of Kevin Kipp wearing a devilish mustache and bandana."
                placeholder="blurred"
                width={612}
                height={816}
              />
            </ProfilePictureContainer>
            <div>
              <Greeting>Hi, I'm Kevin&nbsp;Kipp!</Greeting>
              <Subtitle>UI Engineer based in Austin, TX</Subtitle>
            </div>
            <Links>
              <Link href="https://twitter.com/kevin_kipp">
                <TwitterIcon />
                Twitter
              </Link>
              <Link href="https://github.com/third774">
                <GithubIcon />
                Github
              </Link>
              <Link href="https://www.linkedin.com/in/kevin-kipp/">
                <LinkedinLogo />
                Linkedin
              </Link>
            </Links>
          </Grid>
        </OffsetProfileCard>
      </Wrapper>
    </>
  );
};

export default IndexPage;
