import React, { FC } from "react";
import styled from "styled-components";

const Section = styled.section`
  background-color: var(--color-gray-900);
  border-radius: 1rem;
  padding: 2rem;
  max-width: 30rem;
  filter: drop-shadow(0px 2px 4px hsla(0, 0%, 0%, 0.4))
    drop-shadow(0px 4px 8px hsla(0, 0%, 0%, 0.4))
    drop-shadow(0px 8px 16px hsla(0, 0%, 0%, 0.4))
    drop-shadow(0px 16px 32px hsla(0, 0%, 0%, 0.4));

  @media (min-width: 900px) {
    padding: 3rem;
  }
`;

export const ProfileCard: FC<{ className?: string }> = ({
  children,
  className,
}) => {
  return <Section className={className}>{children}</Section>;
};
