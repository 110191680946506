import { createGlobalStyle } from "styled-components";

export const Theme = createGlobalStyle`
 :root {
	 --color-gray-100: hsl(128, 10%, 10%);
	 --color-gray-200: hsl(128, 6%, 17%);
	 --color-gray-300: hsl(128, 4%, 26%);
	 --color-gray-400: hsl(128, 3%, 39.5%);
	 --color-gray-500: hsl(128, 3%, 42.5%);
	 --color-gray-600: hsl(128, 4%, 55.5%);
	 --color-gray-700: hsl(128, 5%, 68.75%);
	 --color-gray-800: hsl(128, 8%, 81.88%);
	 --color-gray-900: hsl(128, 10%, 95%);

   --color-green-100: hsl(128, 25%, 10%);
   --color-green-200: hsl(128, 29%, 20.5%);
   --color-green-300: hsl(128, 32%, 31%);
   --color-green-400: hsl(128, 34%, 41.5%);
   --color-green-500: hsl(128, 35%, 52%);
   --color-green-600: hsl(128, 36%, 62.5%);
   --color-green-700: hsl(128, 38%, 73%);
   --color-green-800: hsl(128, 41%, 83.5%);
   --color-green-900: hsl(128, 45%, 95%);

   --color-red-100: hsl(8, 67%, 10%);
   --color-red-200: hsl(8, 68%, 20.5%);
   --color-red-300: hsl(8, 71%, 31%);
   --color-red-400: hsl(8, 73%, 41.5%);
   --color-red-500: hsl(8, 75%, 52%);
   --color-red-600: hsl(8, 77%, 62.5%);
   --color-red-700: hsl(8, 79%, 73%);
   --color-red-800: hsl(8, 82%, 83.5%);
   --color-red-900: hsl(8, 83%, 95%);

   --shadow-small: 0px 1px 2px hsla(0, 0%, 0%, .3),
   0px 2px 4px hsla(0, 0%, 0%, .3),
   0px 4px 8px hsla(0, 0%, 0%, .3);
   --shadow-small-inset: inset 0px 1px 2px hsla(0, 0%, 0%, .3),
   inset 0px 2px 4px hsla(0, 0%, 0%, .3),
   inset 0px 4px 8px hsla(0, 0%, 0%, .3);
   --shadow-medium: 0px 2px 4px hsla(0, 0%, 0%, .3),
   0px 4px 8px hsla(0, 0%, 0%, .3),
   0px 8px 16px hsla(0, 0%, 0%, .3);
   --shadow-medium-inset: inset 0px 2px 4px hsla(0, 0%, 0%, .3),
   inset 0px 4px 8px hsla(0, 0%, 0%, .3),
   inset 0px 8px 16px hsla(0, 0%, 0%, .3);
 }

 html {
   font-family: Verdana, Geneva, Tahoma, sans-serif;
   background: var(--color-gray-900);
   color: var(--color-gray-100);
   font-size: clamp(18px, 1.5 * 1vw, 26px);
 }
 
 h1, h2, h3, h4, h5, h6 {
   font-family: Georgia, 'Times New Roman', Times, serif;
 }
`;
